<!--
File: ImportForm.vue
Description: form for bulk import/upload of road data.
-->
<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-card>
        <md-card-content>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <ImportTypesDropdown class='region-select-dropdown' :label="$t('label.select_import_type')"
              v-model="selected_import_type" @input='onImportTypeChange' />
          </div>
          <div class="md-layout-item md-small-size-10.0 md-size-50">
            <md-field>
              <label>{{ $t('traffic.select_file') }}</label>
              <md-file v-model="fileName.name" @md-change="onFileChange($event)" />
            </md-field>
          </div>
        </md-card-content>

        <md-card-actions md-alignment="space-between">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success md-raised" @click="templateDownload" :disabled="!selected_import_type">
              {{ $t('buttons.download_template') }}
            </md-button>
            <md-button class="md-success md-raised" native-type="submit" required @click.native.prevent="validate"
              :disabled="upload_disabled">
              <md-icon>upload</md-icon>
              {{ $t('buttons.import') }}
            </md-button>
            <md-button class="md-accent" @click.stop.prevent="onClose">
              {{ $t('buttons.cancel') }}
            </md-button>
          </div>
        </md-card-actions>
      </md-card>

      <md-card v-if="show_preview == true">
        <md-card-content>
          <div>
            <label><strong>{{ $t('upload.status') }}: </strong> {{ status }} </label><br>
          </div>
          <md-table v-model="import_status" md-fixed-header class="paginated-table table-striped table-hover">
            <md-table-row slot="md-table-row" slot-scope="{item}">
              <md-table-cell :md-label="$t('upload.line_number')">
                {{ item.line_number }}
              </md-table-cell>
              <md-table-cell :md-label="$t('upload.type_description')">
                {{ item.type_description }}
              </md-table-cell>
              <md-table-cell :md-label="$t('upload.is_error')">
                {{ item.is_error == true ? $t('upload.yes') : $t('upload.no') }}
              </md-table-cell>
              <md-table-cell :md-label="$t('upload.message')" md-sort-by="message" width="10%">
                {{ item.message }}
              </md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
  import ImportTypesDropdown from '../Dropdowns/ImportTypesDropdown.vue'
  import messagesMixin from '@/mixins/messagesMixin'
  import { onClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'import-roads',
    mixins: [messagesMixin],

    data() {
      return {
        content: [],
        importFile: null,
        fileName: { name: '', },
        maxFileSize: null,
        selected_import_type: null,
        importTypeKey: null,

        show_preview: false,
        status: null,
        batch_id: null,

        upload_in_progress: false
      }
    },

    components: {
      ImportTypesDropdown
    },

    mounted() {
      this.$store.dispatch('LOAD_SETTING_BY_NAME', 'MaxFileSize').then((res) => {
        this.maxFileSize = res * 1024   // in KB
      })
    },

    methods: {
      onClose,

      onFileChange(evt) {
        this.importFile = evt[0]
        this.show_preview = false
        this.status = null
      },

      templateDownload() {
        const templateType = `GET_${this.importTypeKey.toUpperCase()}_IMPORT_TEMPLATE`

        this.$store.dispatch(templateType).then((res) => {
          //console.log(res.headers)
          const link = document.createElement('a')
          link.href = URL.createObjectURL(new Blob([res.data]))
          link.download = `${this.importTypeKey}_import_template.xlsx` //"road_import_template.xlsx"
          link.click()
          URL.revokeObjectURL(link.href)
        })
      },

      async validate() {
        const fileInfo = `${this.$t('stdCols.name')}: ${this.importFile.name},
          ${this.$t(`label.filesize`, { size: this.importFile.size })}`

        // Checks and validations
        let errors = []
        if (this.importFile.type.includes("image")) {
          errors.push(`${errors.length + 1}. ${this.$t('messages.wrong_file_type')}`)
        }
        if (this.importFile.size > this.maxFileSize) {
          errors.push(`${errors.length + 1}. ${this.$t('messages.big_file')}`)
        }

        if (errors.length > 0) {
          errors.push('', fileInfo)
          this.errorMessage(errors.join('<br>'))
          return
        }

        // We passed all validations - let's try to import
        this.upload_in_progress = true
        let formData = new FormData();
        formData.append('file', this.importFile)
        try {
          res = await this.$store.dispatch(`RUN_${this.importTypeKey.toUpperCase()}_IMPORT`, formData)
          this.status = res.msg + ", " + res.result
          this.batch_id = res.batch_id
          this.successMessage(this.$t('route.import'), this.$t(`messages.import_file_queued`))
          this.importFile = null
          this.fileName.name = ''
        } catch (err) {
          this.status = err.msg
          this.batch_id = err.batch_id
          this.errorMessage(this.$t(`messages.import_error`))
        }
        this.get_log(this.batch_id)
        this.upload_in_progress = false
        this.show_preview = true
      },

      get_log(batch_id) {
        this.$store.dispatch('IMPORT_LOG_ALL', batch_id).then(() => {
          console.log(this.import_status)
        })
      },

      onImportTypeChange(import_type_id, key) {
        this.importTypeKey = key
        this.importFile = null
        this.fileName.name = ''
      }
    },

    computed: {
      import_status() {
        return this.$store.state.ImportTools.import_status
      },

      upload_disabled() {
        return this.importFile === null || this.upload_in_progress === true
      }
    },

    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}
</style>